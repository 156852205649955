<script setup lang="ts">
import { ref, toValue, watch } from 'vue'
import useNavigation from '~/src/UserInterface/App/composables/Navigation/useNavigation'
import workflowPreviousRouteStore from '~/src/UserInterface/App/store/WorkflowPreviousRouteStore'
import useEngagementNavigation
  from '~/src/UserInterface/Engagement/composables/useEngagementNavigation'
import useEngagementState from '~/src/UserInterface/Engagement/composables/useEngagementState'

import useOrganisationNavigation
  from '~/src/UserInterface/Organisation/composables/useOrganisationNavigation'
import useOrganisationState from '~/src/UserInterface/Organisation/composables/useOrganisationState'

const { hasOrganisation, getOrganisation } = useOrganisationState()
const { getCurrentEngagement, hasCurrentEngagement, getCurrentPhase, hasCurrentPhase } = useEngagementState()
const { getLocalePath } = useNavigation()
const store = workflowPreviousRouteStore()
const closeButtonTo = ref('/')
watch(
  [
    () => store.previousRoute,
    hasOrganisation,
    hasCurrentEngagement,
    hasCurrentPhase,
  ],
  ([
    newPreviousRoute,
    newHasOrganisation,
    newHasCurrentEngagement,
    newHasCurrentPhase,
  ],
  ) => {
    if (newPreviousRoute !== undefined) {
      closeButtonTo.value = getLocalePath(toValue(newPreviousRoute))
    } else if (newHasOrganisation && newHasCurrentEngagement && newHasCurrentPhase) {
      const { engagementDashboardLocalePath } = useEngagementNavigation(toValue(getOrganisation()), toValue(getCurrentEngagement()), toValue(getCurrentPhase()))
      closeButtonTo.value = engagementDashboardLocalePath.value
    } else if (newHasOrganisation) {
      const { organisationSettingsEngagementsLocalePath } = useOrganisationNavigation(toValue(getOrganisation()))
      closeButtonTo.value = organisationSettingsEngagementsLocalePath
    } else {
      closeButtonTo.value = getLocalePath({ name: 'landing' })
    }
  },
  {
    immediate: true,
  },
)
</script>

<template>
  <div class="main-content d-flex flex-column">
    <layout-navbar-workflow :close-button-to="closeButtonTo" />

    <div class="flex-grow-1">
      <slot />
    </div>

    <div
      id="footer"
      class="bg-white w-100"
    >
      <layout-footer />
    </div>
    <portal-target id="app-bottom" />
  </div>
</template>
